<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      height="500"
    >
      <el-table-column
        prop="alarmName"
        label="预警指标"
        min-width="200"
      />
      <el-table-column
        min-width="220"
        label="预警规则"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.alarmCode==='outOfStockAlarm'">
            未来<el-input-number v-model="scope.row.alarmRuleParams[0]" :min="1" style="width:100px" :controls="false" :precision="0" />
            天+补货Turnover
          </div>
          <div v-if="scope.row.alarmCode==='invTurnOverAlarm'">
            总库存MPS周转天数大于等于<el-input-number v-model="scope.row.alarmRuleParams[0]" :min="1" :precision="0" style="width:100px" :controls="false" />
            天
          </div>
          <div v-if="['transferDelayOfShipmentAlarm','transferDelayOfDOAlarm'].includes(scope.row.alarmCode)">
            <div>期望上架日期早于预计上架日期<el-input-number v-model="scope.row.alarmRuleParams[0]" :min="1" :precision="0" style="width:100px" :controls="false" />天以上</div>
            <div>期望上架日期、预计上架日期均过期且存在未上架数量</div>
          </div>

        </template>
      </el-table-column>
      <el-table-column
        label="提醒频率"
        min-width="140"
      >
        <template slot-scope="scope">
          <el-select v-model="scope.row.alarmRate" filterable>
            <el-option
              v-for="(item,index) in alarmRateList"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="提醒方式"
        min-width="140"
      >
        <template slot-scope="scope">
          <el-select v-model="scope.row.alarmMethod" filterable>
            <el-option
              v-for="(item,index) in alarmMethodList"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>

        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="120"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.useStatus"
            :active-value="1"
            :inactive-value="2"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="mt-3" justify="center">
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">保存</el-button>
    </el-row>
  </div>
</template>
<script>

import { AlarmConfigList, AlarmConfigUpdate, listAlarmMethodEnumAll, listAlarmRateEnumALl } from '@/api/configuration'
export default {

  data() {
    return {
      tableData: [],
      submitLoading: false,
      loading: false,
      alarmMethodList: [],
      alarmRateList: []

    }
  },

  mounted() {
    this.handleQuery()
    this._listAlarmMethodEnumAll()
    this._listAlarmRateEnumALl()
  },
  methods: {
    async _listAlarmMethodEnumAll() {
      const { datas } = await listAlarmMethodEnumAll()
      this.alarmMethodList = datas
    },
    async _listAlarmRateEnumALl() {
      const { datas } = await listAlarmRateEnumALl()
      this.alarmRateList = datas
    },
    async handleQuery() {
      try {
        this.loading = true
        const { datas } = await AlarmConfigList()
        this.tableData = datas
      } finally {
        this.loading = false
      }
    },
    isNullArray(value) {
      return Array.isArray(value) && value.length && value.some(v => !v)
    },
    async handleSubmit() {
      try {
        if (this.tableData.some(v => this.isNullArray(v.alarmRuleParams))) return this.$message.warning('预警规则天数必填')
        this.submitLoading = true
        await AlarmConfigUpdate(this.tableData)
        this.$message.success('操作成功')
        this.handleQuery()
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
<style scoped>
.el-input__inner {
  width:100px;
}
</style>
